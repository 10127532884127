import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import React from "react"

import { cn } from "@/utils/utils"

const ButtonVariants = cva(
  "box-border inline-flex w-max cursor-pointer justify-center items-center whitespace-nowrap rounded-full border-2 border-transparent no-underline font-medium leading-[1.2] text-lg xl:text-xl tracking-[0.015em] transition-colors",
  {
    variants: {
      variant: {
        primary: "bg-primary-50 text-on-primary-50 hover:bg-primary-70 hover:text-on-primary-70",
        secondary: "bg-secondary-50 text-on-secondary-50 hover:bg-on-secondary-30 hover:text-on-secondary-50",
        primary_outlined: "border-2 border-primary-50 text-primary-50 hover:bg-hover-primary active:bg-hover-primary",
        secondary_outlined: "border-2 border-secondary-50 text-secondary-50",
        primary_light: "bg-primary-10 text-on-primary-10",
        tertiary: "text-primary-50",
        ghost: "underline decoration-2",
        disabled: "pointer-events-none bg-neutral-10 text-neutral-30",
        accent: "bg-accent text-on-accent"
      },
      size: {
        default: "px-6 py-2",
        custom: "lg:px-6 lg:py-2 px-4 py-1",
        small: "text-md px-4 py-2 xl:text-md"
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "default"
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof ButtonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"

    return <Comp className={cn(ButtonVariants({ variant, size, className }))} ref={ref} {...props} />
  }
)

Button.displayName = "Button"

export { Button, ButtonVariants }
